
let HOST = 'http://localhost:3000/api/v1/';
// export const BASE_URL = "http://localhost:3000/api/v1/";
export const BASE_URL = "https://node.mystorybank.info:4000/api/v1/";
export const BASE_URL_IMAGE =" https://node.mystorybank.info:4000/images/"
export const BASE_URL_VIDEO =" https://node.mystorybank.info:4000/videos/"

export default HOST;


